<!-- 考试大类 -->
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
<script>
import Layout from "@/views/layouts/main";
import UserHeader from "../user-header.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    UserHeader,
  },
  data() {
    return {
      items: [
        {
          text: "用户首页",
          href: "/admin",
        },
        {
          text: "用户管理",
          active: true,
        },
      ],
      checked: true,
      defaultvalue: 3,
      currentpage: 3,
      tableList: [
        {
          id: 1,
          jgmc: "西华大学",
          jgdm: "01",
          jgjb: "—级",
          xxsh: "已审核",
          lxr: "张三",
          dh: "13890660600",
        },
        {
          id: 2,
          jgmc: "西华大学",
          jgdm: "01",
          jgjb: "—级",
          xxsh: "已审核",
          lxr: "张三",
          dh: "13890660600",
        },
        {
          id: 3,
          jgmc: "西华大学",
          jgdm: "01",
          jgjb: "—级",
          xxsh: "已审核",
          lxr: "张三",
          dh: "13890660600",
        },
        {
          id: 4,
          jgmc: "西华大学",
          jgdm: "01",
          jgjb: "—级",
          xxsh: "已审核",
          lxr: "张三",
          dh: "13890660600",
        },
        {
          id: 5,
          jgmc: "西华大学",
          jgdm: "01",
          jgjb: "—级",
          xxsh: "已审核",
          lxr: "张三",
          dh: "13890660600",
        },
        {
          id: 6,
          jgmc: "西华大学",
          jgdm: "01",
          jgjb: "—级",
          xxsh: "已审核",
          lxr: "张三",
          dh: "13890660600",
        },
      ],
      tableList2: [
        {
          id: 1,
          xm: "姜学坤",
          ssjg: "四川省房建局",
          js: "机构管理员",
          shqk: "已审核",
          qx: "11011",
          dh: "13890660600",
        },
        {
          id: 2,
          xm: "姜学坤",
          ssjg: "四川省房建局",
          js: "机构管理员",
          shqk: "已审核",
          qx: "11011",
          dh: "13890660600",
        },
        {
          id: 3,
          xm: "姜学坤",
          ssjg: "四川省房建局",
          js: "机构管理员",
          shqk: "已审核",
          qx: "11011",
          dh: "13890660600",
        },
        {
          id: 4,
          xm: "姜学坤",
          ssjg: "四川省房建局",
          js: "机构管理员",
          shqk: "已审核",
          qx: "11011",
          dh: "13890660600",
        },
        {
          id: 5,
          xm: "姜学坤",
          ssjg: "四川省房建局",
          js: "机构管理员",
          shqk: "已审核",
          qx: "11011",
          dh: "13890660600",
        },
        {
          id: 6,
          xm: "姜学坤",
          ssjg: "四川省房建局",
          js: "机构管理员",
          shqk: "已审核",
          qx: "11011",
          dh: "13890660600",
        },
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <UserHeader :items="items" />

    <div class="row">
      <div class="col-lg-6">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <h4 class="card-title blue-font">机构列表</h4>
            <div class="table-responsive border mt-4">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%"></th>
                    <th>机构名称</th>
                    <th style="width: 12%">机构代码</th>
                    <th style="width: 10%">机构级别</th>
                    <th style="width: 10%">是否启用</th>
                    <th style="width: 10%">信息审核</th>
                    <th style="width: 8%">联系人</th>
                    <th style="width: 15%">电话</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="obj in tableList" :key="obj.id">
                    <td scope="row">{{ obj.id }}</td>
                    <td>{{ obj.jgmc }}</td>
                    <td class="text-info">{{ obj.jgdm }}</td>
                    <td>{{ obj.jgjb }}</td>
                    <td class="text-center">
                      <b-form-checkbox
                        v-model="checked"
                        switch
                        class="switch-check"
                      >
                      </b-form-checkbox>
                    </td>
                    <td>{{ obj.xxsh }}</td>
                    <td>{{ obj.lxr }}</td>
                    <td>{{ obj.dh }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="check-table-top">
              <div class="flexList">
                <b-button
                  v-b-modal.add-insititution
                  variant="outline-info"
                  class="flexList mr-2 condition"
                  ><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i
                  >添加机构用户</b-button
                >
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab">
                  <i class="iconfont icon-antOutline-file-excel mr-2"></i>excel
                </div>
                <div class="border-blue export-tab">
                  <i class="iconfont icon-data mr-2"></i>dbf
                </div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%"></th>
                    <th style="width: 10%">姓名</th>
                    <th>所属机构</th>
                    <th style="width: 15%">角色</th>
                    <th style="width: 10%">是否启用</th>
                    <th style="width: 10%">审核情况</th>
                    <th style="width: 8%">权限</th>
                    <th style="width: 10%">电话</th>
                    <th style="width: 8%">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="obj in tableList2" :key="obj.id">
                    <td scope="row">{{ obj.id }}</td>
                    <td>{{ obj.xm }}</td>
                    <td>
                      <a href="" class="blue-font">{{ obj.ssjg }}</a>
                    </td>
                    <td>{{ obj.js }}</td>
                    <td>
                      <b-form-checkbox
                        v-model="checked"
                        switch
                        class="switch-check"
                      >
                      </b-form-checkbox>
                    </td>
                    <td>{{ obj.shqk }}</td>
                    <td>{{ obj.qx }}</td>
                    <td>{{ obj.dh }}</td>
                    <td class="tab-icon">
                      <a href="/admin/institutionCheck"
                        ><i
                          class="iconfont icon-edit-two text-dark align-middle mr-1"
                        ></i></a
                      ><i
                        class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->
    <b-modal
      id="add-insititution"
      centered
      title="添加机构用户"
      size="lg"
      title-class="font-18 big-blue-title"
      hide-footer
    >
      <div class="insti-box">
        <div class="d-flex mb-3">
          <div class="insti-name flexList">
            <i class="iconfont icon-idcard"></i>机构
          </div>
          <div class="insti-val flexList">
            <select name="" class="form-control form-select mr-2">
              <option value="">输入关键字查询</option>
              <option value="">西华大学</option>
              <option value="">四川省人社厅</option>
              <option value="">四川省房建局</option>
              <option value="">内江市人民政府</option>
              <option value="">乐山市人民政府</option>
              <option value="">德阳市</option>
              <option value="">达州市</option>
            </select>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList">
            <i class="iconfont icon-idcard"></i>姓名
          </div>
          <div class="insti-val flexList">
            <input type="text" name="" id="" value="" class="no-border" />
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList">
            <i class="iconfont icon-idcard"></i> 身份证号
          </div>
          <div class="insti-val flexList">
            <input type="text" name="" id="" value="" class="no-border" />
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList">
            <i class="iconfont icon-idcard"></i> 部门
          </div>
          <div class="insti-val flexList">
            <select name="" class="form-control form-select mr-2">
              <option value="">综合科</option>
              <option value="">人事科</option>
            </select>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList">
            <i class="iconfont icon-idcard"></i> 职务
          </div>
          <div class="insti-val flexList">
            <input type="text" name="" id="" value="" class="no-border" />
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList">
            <i class="iconfont icon-idcard"></i> 类别
          </div>
          <div class="insti-val flexList">
            <select name="" class="form-control form-select mr-2">
              <option value="">机构管理员</option>
              <option value="">机构一般审核员</option>
              <option value="">机构终审员</option>
            </select>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList">
            <i class="iconfont icon-idcard"></i> 权限
          </div>
          <div class="insti-val flexList">
            <select name="" class="form-control form-select mr-2">
              <option value="">默认权限</option>
              <option value="">审核权限</option>
            </select>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="insti-name flexList">
            <i class="iconfont icon-cell_phone"></i> 手机
          </div>
          <div class="insti-val flexList">
            <input type="tel" name="" id="" value="" class="no-border" />
          </div>
        </div>
        <div class="d-flex">
          <div class="insti-name flexList">
            <i class="iconfont icon-a-md-lock_outlineCopy"></i> 密码
          </div>
          <div class="insti-val flexList">
            <input type="text" name="" id="" value="" class="no-border" />
          </div>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="sub-btn btn btn-info">添加机构用户</button>
      </div>
    </b-modal>
    <!-- 弹窗结束 -->
  </Layout>
</template>
